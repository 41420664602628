<template>
  <v-container class="content" fluid>
    <v-row class="mt-4" justify="start">
      <v-btn @click="back()">
        <v-icon>arrow_back</v-icon> {{ $t("back") }}
      </v-btn>
    </v-row>
    <v-divider class="mt-4 mb-4"></v-divider>
    <v-row class="white lighten-5">
      <span v-if="static_page" v-html="static_page.html"></span>
    </v-row>
  </v-container>
</template>

<script>
import engineStore from "@/services/engineStore";

export default {
  name: "Static_PageDetail",
  data() {
    return {
      static_page: {},
    };
  },
  watch: {
    $route: "mounted",
  },
  created() {
    this.load();
  },
  mounted() {
    // Images are generated dynamically so we cant use CSS directly
    const images = document.getElementsByTagName("img");
    for (let i = 0; i < images.length; i++) {
      images[i].style.maxWidth = "100%";
    }
  },
  methods: {
    load() {
      this.static_page = engineStore.statics.find(
        (s) => s.id === this.$route.params.id
      );
    },
    back() {
      this.$router.go(-1);
    },
  },
};
</script>
<style scoped>
.content {
  width: 80%;
  margin: 0 auto;
}

@media only screen and (max-width: 600px) {
  .content {
    width: 100%;
  }
}
</style>
