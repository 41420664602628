var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-container",
    { staticClass: "content", attrs: { fluid: "" } },
    [
      _c(
        "v-row",
        { staticClass: "mt-4", attrs: { justify: "start" } },
        [
          _c(
            "v-btn",
            {
              on: {
                click: function ($event) {
                  return _vm.back()
                },
              },
            },
            [
              _c("v-icon", [_vm._v("arrow_back")]),
              _vm._v(" " + _vm._s(_vm.$t("back")) + " "),
            ],
            1
          ),
        ],
        1
      ),
      _c("v-divider", { staticClass: "mt-4 mb-4" }),
      _c("v-row", { staticClass: "white lighten-5" }, [
        _vm.static_page
          ? _c("span", {
              domProps: { innerHTML: _vm._s(_vm.static_page.html) },
            })
          : _vm._e(),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }